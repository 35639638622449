import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';

import Layout from '@/components/Layouts';
import Router from '@/router';

import { PermissionsProvider } from '@/libraries/accessManager/access/permissionsProvider';
import useDebugger from '@/hooks/useDebugger';
import useResizeUi from '@/hooks/useResizeUi';
import useStore from '@/hooks/useStore';
import useGetUserData from '@/hooks/useGetUserData';
import CustomBackdrop from '@/components/CustomBackdrop';

const App: FC = () => {
  const { loginStore, roleStore } = useStore();
  const { loadUserData, isLoadingUserData } = useGetUserData();

  useResizeUi();
  useDebugger();

  useEffect(() => {
    if (!loginStore.flags.isAuthenticated) {
      loadUserData();
    }
  }, [loginStore.flags.isAuthenticated]);

  if (isLoadingUserData) {
    return <CustomBackdrop loading />;
  }

  return (
    <PermissionsProvider accessActions={roleStore.allowedActions} accessRole={roleStore.role}>
      <Layout>
        <Router />
      </Layout>
    </PermissionsProvider>
  );
};

export default observer(App);
