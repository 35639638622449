import { types, applySnapshot, getSnapshot, Instance, flow, cast, getRoot } from 'mobx-state-tree';
import axios from 'axios';
import debug from 'debug';

import { IGlobalStore } from '@/store';
import endpoints from '@/config/endpoints';
import Flags from './models/Flags';
import Business from './models/Business';

const log = debug('store:business:log');
const errorLog = debug('store:business:error');

const BusinessStore = types
  .model({
    flags: types.optional(Flags, {}),
    businessList: types.optional(types.array(Business), []),
  })
  .views((self) => ({
    get root(): IGlobalStore {
      return getRoot(self);
    },
  }))
  .actions((self) => ({
    updateField: (field: string, value: string | number | boolean | null) => {
      applySnapshot(self, { ...self, [field]: value });
    },
  }))
  .actions((self) => {
    let initialState = {};
    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      resetStore: () => {
        applySnapshot(self, initialState);
      },
      delay: (ms: number) =>
        new Promise((resolve) => {
          setTimeout(resolve, ms);
        }),
    };
  })
  .actions((self) => ({
    getBusinessList: flow(function* () {
      log('getBusinessList >>>>');
      try {
        self.flags.isFetchingBusinessListData = true;
        const response = yield axios.get(endpoints.getBusinessList);
        log('getBusinessListResponse >>>>', response);
        if (response.data.success) {
          self.businessList = cast(response.data.businessList);
        }
      } catch (err) {
        errorLog('getBusinessListError >>>>', err);
      } finally {
        self.flags.isFetchingBusinessListData = false;
      }
    }),
  }));

export interface IBusinessStore extends Instance<typeof BusinessStore> {}

export default BusinessStore;
