import React, { StrictMode } from 'react';
import { ThemeProvider as ThemeProviderV4 } from '@material-ui/core/styles';
import { ThemeProvider as ThemeProviderV5 } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';

import { StoreContext } from '@/hooks/useStore';
import theme from '@/config/theme';
import mui5Theme from '@/config/theme/mui5';
import axiosConfig from '@/config/axiosConfig';

import { ToastProvider } from '@/components/ToastWrapper/ToastContext';
import { ModalProvider } from '@/components/ModalWrapper/ModalContext';
import { DrawerProvider } from '@/components/DrawerWrapper/DrawerContext';

import store from '@/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';

axiosConfig();

/* const generateClassName = createGenerateClassName({
  // By enabling this option, if you have non-MUI elements (e.g. `<div />`)
  // using MUI classes (e.g. `.MuiButton`) they will lose styles.
  // Make sure to convert them to use `styled()` or `<Box />` first.
  disableGlobal: true,
  // Class names will receive this seed to avoid name collisions.
  seed: 'mui-jss',
}); */

ReactDOM.render(
  <StrictMode>
    <ThemeProviderV4 theme={theme}>
      <ThemeProviderV5 theme={mui5Theme}>
        <StoreContext.Provider value={store}>
          <BrowserRouter>
            <ToastProvider>
              <ModalProvider>
                <DrawerProvider>
                  <App />
                </DrawerProvider>
              </ModalProvider>
            </ToastProvider>
          </BrowserRouter>
        </StoreContext.Provider>
      </ThemeProviderV5>
    </ThemeProviderV4>
  </StrictMode>,
  document.getElementById('root'),
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
