import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Grid from '@material-ui/core/Grid';

import FormTitle from '@/components/FormTitle';
import Input from '@/components/Input';
import PasswordInput from '@/components/PasswordInput';
import FormButton from '@/components/FormButton';

import useStore from '@/hooks/useStore';
import useToast from '@/hooks/useToast';
import useStyles from './styles';

import { ILoginForm } from './types';

const Login: FC = () => {
  const css = useStyles();
  const { uiStore, loginStore } = useStore();
  const { openToast } = useToast();
  const { flags, login } = loginStore;

  const mobileWidth = uiStore.width <= 640;

  const validationSchema = yup.object().shape({
    email: yup.string().email('Ingresa un email valido').required('Ingresa un email valido'),
    password: yup.string().required('Ingresa tu contraseña'),
  });

  const {
    control,
    handleSubmit,
    formState: { isValid },
    // reset,
  } = useForm<ILoginForm>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const loginRequest = async (form: ILoginForm) => {
    try {
      await login({
        email: form.email,
        pass: form.password,
      });
    } catch (err: any) {
      openToast({
        type: 'error',
        title: err?.title,
        message: err?.msg,
        duration: 3500,
      });
    }
  };

  const buttonDisabled = !isValid || flags.isFetchingLogin;

  return (
    <form className={css.formContainer} onSubmit={handleSubmit(loginRequest)}>
      <Grid container spacing={2} className={css.noAuthContainer}>
        <FormTitle
          className={css.formNoAuth}
          title="Iniciar sesión"
          info="Ingresa tus datos de usuario"
          titleCss={css.title}
          infoCss={css.text}
        />
        <Grid item xs={12} className={css.formNoAuth}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ fieldState, field: { ref, ...rest } }) => (
              <Input
                {...rest}
                fullWidth
                inputRef={ref}
                error={fieldState.invalid}
                type="email"
                label="Email"
                inputProps={{
                  type: 'email',
                  autoComplete: 'off',
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} className={css.formNoAuth}>
          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ fieldState, field: { ref, ...rest } }) => (
              <PasswordInput
                {...rest}
                fullWidth
                inputRef={ref}
                error={fieldState.invalid}
                label="Contraseña"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} className={css.formNoAuth}>
          <div className={css.buttonContainer}>
            <FormButton
              type="submit"
              size="large"
              fullWidth={mobileWidth}
              submitting={flags.isFetchingLogin}
              disabled={buttonDisabled}
            >
              Continuar
            </FormButton>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

export default observer(Login);
