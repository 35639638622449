import { types, applySnapshot, getSnapshot, Instance, flow, cast, getRoot } from 'mobx-state-tree';
import axios from 'axios';
import debug from 'debug';

import { IGlobalStore } from '@/store';
import endpoints from '@/config/endpoints';
import Flags from './models/Flags';

const log = debug('store:role:log');
const errorLog = debug('store:role:error');

const RoleStore = types
  .model({
    role: types.optional(types.string, ''),
    allowedActions: types.array(types.string),
    flags: types.optional(Flags, {}),
  })
  .views((self) => ({
    get root(): IGlobalStore {
      return getRoot(self);
    },
  }))
  .actions((self) => ({
    updateField: (field: string, value: string | number | boolean | null) => {
      applySnapshot(self, { ...self, [field]: value });
    },
  }))
  .actions((self) => {
    let initialState = {};
    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      resetStore: () => {
        applySnapshot(self, initialState);
      },
      delay: (ms: number) =>
        new Promise((resolve) => {
          setTimeout(resolve, ms);
        }),
    };
  })
  .actions((self) => ({
    getRoleData: flow(function* () {
      const { userStore } = self.root;

      log('getRoleData >>>>');
      try {
        self.flags.isFetchingRoleData = true;
        const response = yield axios.get(endpoints.getRole);
        log('getRoleDataResponse >>>>', response);
        if (response.data.success) {
          self.role = response.data.roleData.role;
          self.allowedActions = cast(response.data.roleData.actions);
        }
      } catch (err) {
        errorLog('getRoleDataError >>>>', err);
      } finally {
        self.flags.isFetchingRoleData = false;
        userStore.flags.updateFlag('isFetchingUserData', false);
      }
    }),
  }));

export interface IRoleStore extends Instance<typeof RoleStore> {}

export default RoleStore;
