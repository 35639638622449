import { useEffect } from 'react';
import { env } from '@/config/variables';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useDebugger() {
  useEffect(() => {
    if (env !== 'prod') {
      localStorage.setItem('debug', '*');
    } else {
      localStorage.removeItem('debug');
    }
  }, []);
}

export default useDebugger;
