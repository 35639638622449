import React, { FC, ReactNode } from 'react';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';

import useStore from '@/hooks/useStore';
import { ROUTES } from '@/constants/routes';

interface UnAuthRouteProps {
  children: ReactNode;
}

const UnAuthRoute: FC<UnAuthRouteProps> = ({ children }) => {
  const { loginStore } = useStore();
  const isAuthenticated = loginStore.flags.isAuthenticated;

  if (!isAuthenticated) {
    return <>{children}</>;
  }

  return <Redirect from="*" to={ROUTES.HOME} />;
};

export default observer(UnAuthRoute);
