import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useIconStyles = makeStyles(() =>
  createStyles({
    root: {
      borderRadius: 30,
      padding: '0.5rem 0.1rem',
      width: 'auto',
      minWidth: 30,
      fontSize: 12,
    },
  }),
);

export const useSnackbarStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'unset',
      transform: 'unset',
      left: 'unset',
      right: 'unset',
      top: 'unset',
    },
  }),
);

export const useAlertStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 25,
      borderRadius: 40,
      backgroundColor: theme.andorTheme.cotton,
      color: theme.andorTheme.obsidian,
      fontSize: 14,
      fontWeight: 'normal',
      maxWidth: 500,
      pointerEvents: 'all',
      boxShadow: `0px 2px 10px -2px rgb(0 0 0 / 14%),
      0px 2px 2px 0px rgb(0 0 0 / 7%),
      0px 1px 8px 0px rgb(0 0 0 / 6%)`,
      '& .MuiAlertTitle-root': {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: '16px',
        color: theme.palette.success.main,
      },
      '&.toast-Error': {
        '& .MuiAlertTitle-root': {
          color: theme.palette.error.main,
        },
      },
    },
    icon: {
      alignSelf: 'center',
      fontSize: 24,
    },
  }),
);
