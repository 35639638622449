const allowedActions = {
  HOME_SCREEN: 'home_screen',
  ACCIDENTS_SECTION: 'accidents_section',
  CREATE_ACCIDENT: 'create_accident',
  ACCIDENTS_TOOLS: 'accidents_tools',
  ACCIDENTS_DETAIL_SECTION: 'accidents_detail_section',
  EDIT_ACCIDENT: 'edit_accident',
  ACCIDENTS_OPTIONS: 'accidents_options',
  DELETE_ACCIDENT: 'delete_accident',
  ACCIDENT_VISIT_DEAL: 'accident_visit_deal',
  CREATE_LOSS: 'create_loss',
  DELETE_LOSS: 'delete_loss',
  EDIT_LOSS: 'edit_loss',
  CREATE_ADJUST_LOSS: 'create_adjust_loss',
  DELETE_ADJUST_LOSS: 'delete_adjust_loss',
  EDIT_ADJUST_LOSS: 'edit_adjust_loss',
  UPLOAD_ACCIDENT_EVIDENCE_IMAGE: 'upload_accident_evidence_image',
  DELETE_ACCIDENT_EVIDENCE_IMAGE: 'delete_accident_evidence_image',
  VIEW_ACCIDENT_REPORT: 'view_accident_report',
  EDIT_ACCIDENT_REPORT: 'edit_accident_report',
} as const;

export { allowedActions };
