import { Instance, types } from 'mobx-state-tree';

const AccidentLossSummary = types.model({
  totalNumberOfPieces: types.maybeNull(types.number),
  totalUnitAmount: types.maybeNull(types.number),
  subTotal: types.maybeNull(types.number),
  total: types.maybeNull(types.number),
});

export interface IAccidentLossSummary extends Instance<typeof AccidentLossSummary> {}
export default AccidentLossSummary;
