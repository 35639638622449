import { useContext } from 'react';
import { ToastContext } from '@/components/ToastWrapper/ToastContext';
import { IToast } from '@/components/ToastWrapper/types';

interface IUseToast {
  openToast(toast: IToast): void;
}

const useToast = (): IUseToast => {
  const { addToast } = useContext(ToastContext);

  const openToast = (toast: IToast): void => {
    addToast(toast);
  };

  return { openToast };
};

export default useToast;
