import { useCallback } from 'react';

import useStore from '@/hooks/useStore';

export interface IUseGetUserData {
  loadUserData: () => void;
  isLoadingUserData: boolean;
}

function useGetUserData(): IUseGetUserData {
  const { userStore, roleStore } = useStore();
  const isLoadingUserData = userStore.flags.isFetchingUserData;

  const loadUserData = useCallback(async () => {
    try {
      await userStore.getUserData();
      await roleStore.getRoleData();
    } catch {
      // empty catch for now
    }
  }, []);

  return { loadUserData, isLoadingUserData };
}

export default useGetUserData;
