import React, { FC } from 'react';
import Backdrop from '@material-ui/core/Backdrop';

import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from './styles';

interface CustomBackdropProps {
  loading: boolean;
}

const CustomBackdrop: FC<CustomBackdropProps> = ({ loading }) => {
  const css = useStyles();
  return (
    <Backdrop className={css.backdrop} open={loading}>
      {loading && <CircularProgress color="primary" />}
    </Backdrop>
  );
};

export default CustomBackdrop;
