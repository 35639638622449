import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import useStore from '@/hooks/useStore';
import { ROUTES } from '@/constants/routes';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useLogout() {
  const { loginStore } = useStore();
  const history = useHistory();

  const logoutUser = useCallback(async () => {
    try {
      await loginStore.logoutReset();
    } finally {
      history.push(ROUTES.LOGIN);
    }
  }, []);

  return { logoutUser };
}

export default useLogout;
