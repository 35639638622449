import { Instance, types } from 'mobx-state-tree';

const AccidentLoss = types.model({
  id: types.maybeNull(types.string),
  accidentExpensesId: types.maybeNull(types.string),
  accidentExpensesFileId: types.maybeNull(types.string),
  concept: types.maybeNull(types.string),
  wellDamaged: types.maybeNull(types.string),
  bill: types.maybeNull(types.string),
  date: types.maybeNull(types.string),
  supplier: types.maybeNull(types.string),
  numberOfPieces: types.maybeNull(types.number),
  unitAmount: types.maybeNull(types.number),
  totalAmount: types.maybeNull(types.number),
  currency: types.maybeNull(types.string),
  exchangeRate: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  documentType: types.maybeNull(types.string),
});

export interface IAccidentLoss extends Instance<typeof AccidentLoss> {}
export default AccidentLoss;
