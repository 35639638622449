export default {
  test: '/test',
  login: '/login',
  getUser: '/user',
  getUserList: '/user/list',
  getRole: '/role',
  getAccidentList: '/accidents/list',
  getAccident: (accidentId: string) => `/accidents/${accidentId}/detail`,
  getAccidentTypesList: '/accidents/types/list',
  createAccident: '/accidents/create',
  updateAccident: '/accidents/update',
  updateAccidentVisit: '/accidents/update/visit',
  deleteAccident: '/accidents/delete',
  getBusinessList: '/business/list',
  getPoliciesList: '/policies/list',
  getInsurersList: '/insurers/list',
  createAccidentLoss: '/accidents/create/loss',
  createAccidentAdjustLoss: '/accidents/create/adjust/loss',
  updateAccidentAdjustLoss: '/accidents/update/adjust/loss',
  getAccidentLossList: (accidentId: string) => `/accidents/${accidentId}/loss/list`,
  getAccidentLossSummary: (accidentId: string) => `/accidents/${accidentId}/loss/summary`,
  notProceedAccident: '/accidents/not-proceed',
  deleteAccidentLoss: (accidentExpensesId: string) =>
    `/accidents/loss/${accidentExpensesId}/delete`,
  deleteAccidentAdjustLoss: (accidentAdjustExpensesId: string) =>
    `/accidents/adjust/loss/${accidentAdjustExpensesId}/delete`,
  createAccidentEvidenceImage: 'accidents/evidence/image',
  getAccidentEvidencesImages: (accidentId: string) => `/accidents/${accidentId}/evidences/images`,
  deleteAccidentEvidenceImage: (accidentEvidenceImageId: string) =>
    `/accidents/${accidentEvidenceImageId}/evidence/image`,
  saveAccidentReport: '/accidents/save/report',
  getAccidentReport: (accidentId: string) => `/accidents/${accidentId}/report`,
};
