import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import SideBarBg from '@/assets/images/login-sidebar-bg.png';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflowX: 'hidden',
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      height: '100%',
      margin: 0,
      padding: 0,
      alignItems: 'stretch',
      backgroundColor: theme.andorTheme.cotton,
    },
    sidebar: {
      width: '533px',
      minWidth: '533px',
      height: '100%',
      minHeight: '100vh',
      position: 'fixed',
      background: `${theme.andorTheme.pearl}
          url(${SideBarBg}) no-repeat`,
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      '@media (max-width: 1400px)': {
        width: '452px',
        minWidth: '452px',
      },
      '@media (max-width: 1100px)': {
        display: 'none',
      },
    },
    content: {
      display: 'flex',
      width: 'calc(100% - 533px)',
      flex: '1',
      flexDirection: 'column',
      height: '100%',
      minHeight: '100vh',
      position: 'absolute',
      alignItems: 'flex-start',
      top: 0,
      right: 0,
      bottom: 0,
      left: '533px',
      '@media (max-width: 1400px)': {
        width: 'calc(100% - 452px)',
        left: '452px',
      },
      '@media (max-width: 1100px)': {
        width: '100%',
        left: 0,
      },
    },
    header: {
      padding: '2rem 1rem',
      display: 'flex',
      width: '100%',
      maxWidth: '68.75em',
      margin: '0 auto',
      position: 'relative',
      '@media (max-width: 1260px)': {
        maxWidth: '720px',
      },
    },
    contentHeader: {
      display: 'flex',
      width: 'calc(100% - 533px)',
      position: 'fixed',
      top: 0,
      right: 0,
      left: '533px',
      zIndex: 100,
      '@media (max-width: 1400px)': {
        width: 'calc(100% - 452px)',
        left: '452px',
      },
      '@media (max-width: 1100px)': {
        left: 0,
        width: '100%',
        alignItems: 'center',
      },
      '@media (max-width: 640px)': {
        textAlign: 'center',
      },
    },
    contentHeaderPad: {
      width: '100%',
      maxWidth: '68.75em',
      margin: '0',
      display: 'flex',
      alignSelf: 'center',
      alignItems: 'center',
      padding: '2rem 1rem 2rem 5rem',
      '@media (max-width: 1260px)': {
        maxWidth: '720px',
      },
      '@media (max-width: 1100px)': {
        alignSelf: 'center',
        padding: '2rem 1rem',
        margin: '0 auto',
      },
    },
    headerAction: {
      marginLeft: 'auto',
      fontSize: '14px',
      textAlign: 'right',
      '@media (max-width: 640px)': {
        marginRight: 'auto',
      },
    },
    headerActionBtn: {
      color: theme.andorTheme.andor,
      fontFamily: 'Montserrat',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '20px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    logo: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& img': {
        width: '200px',
      },
      '@media (max-width: 1100px)': {
        alignItems: 'flex-start',
        marginRight: 'auto',
        maxWidth: '230px',
        '& img': {
          alignSelf: 'flex-start',
        },
      },
      '@media (max-width: 640px)': {
        alignItems: 'center',
        maxWidth: '100%',
      },
    },
    containerBody: {
      padding: '86px 1rem 86px 5rem',
      paddingBottom: '140px',
      display: 'flex',
      alignSelf: 'flex-start',
      height: '100%',
      width: '100%',
      maxWidth: '68.75em',
      margin: '0',
      '@media (max-width: 1260px)': {
        maxWidth: '720px',
      },
      '@media (max-width: 1100px)': {
        alignSelf: 'center',
        padding: '86px 1rem',
      },
      '@media (max-width: 640px)': {
        padding: '94px 1rem 0 1rem',
      },
    },
  }),
);

export default styles;
