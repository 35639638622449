import axios from 'axios';

import { AuthCookie } from '@/utils/authCookie';
import { ROUTES } from '@/constants/routes';

import { baseUrl } from './variables';
import endpoints from './endpoints';

const axiosConfig = async () => {
  axios.defaults.baseURL = baseUrl;
  axios.interceptors.request.use((config) => {
    config.headers.Accept = 'application/json';
    config.headers['AuthToken'] = AuthCookie.getCookie();
    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      const { error, msg } = response.data;
      const requestUrl = response?.config?.url || '';
      const noAuthMsg = msg === 'No autorizado';
      const isNoAuth = noAuthMsg || response.status === 500;

      const isFromLogin = window.location.pathname === '/login';
      const isAuthRequest = [endpoints.getUser].includes(requestUrl);

      if (isNoAuth) {
        const token = AuthCookie.getCookie();
        if (token) AuthCookie.deleteCookie();
      }

      if (isNoAuth && isAuthRequest && !isFromLogin) {
        window.location.href = ROUTES.LOGIN;
      }

      if (error) return Promise.reject(response.data);
      return response;
    },
    (error) => Promise.reject(error),
  );
};

export default axiosConfig;
