import Cookies from 'js-cookie';
import debug from 'debug';
import { cookieName } from '@/config/variables';

const log = debug('util:authCookie:log');

const isLocalhost = window.location.hostname === 'localhost';
const cookieConfig = isLocalhost ? {} : { domain: process.env.REACT_APP_COOKIE_DOMAIN };

export const getExpiration = (minutes: number) =>
  new Date(new Date().getTime() + 60 * minutes * 1000);

/* Auth cookie */
export class AuthCookie {
  static setCookie(value: string) {
    console.log(window.location.hostname);
    log('AuthCookie setCookie >>>> ', cookieName, value);
    Cookies.set(cookieName, value, { expires: getExpiration(10080), ...cookieConfig });
  }

  static getCookie() {
    const cookie = Cookies.get(cookieName);
    return cookie || '';
  }

  static deleteCookie() {
    log('AuthCookie deleteCookie >>>> ', cookieName);
    Cookies.set(cookieName, '', { expires: getExpiration(10080), ...cookieConfig });
  }
}
