import { types, applySnapshot, getSnapshot, Instance, flow, cast, getRoot } from 'mobx-state-tree';
import axios from 'axios';
import debug from 'debug';

import { IGlobalStore } from '@/store';
import endpoints from '@/config/endpoints';
import Flags from './models/Flags';
import Policies from './models/Policies';

const log = debug('store:policies:log');
const errorLog = debug('store:policies:error');

const PoliciesStore = types
  .model({
    flags: types.optional(Flags, {}),
    policiesList: types.optional(types.array(Policies), []),
  })
  .views((self) => ({
    get root(): IGlobalStore {
      return getRoot(self);
    },
  }))
  .actions((self) => ({
    updateField: (field: string, value: string | number | boolean | null) => {
      applySnapshot(self, { ...self, [field]: value });
    },
  }))
  .actions((self) => {
    let initialState = {};
    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      resetStore: () => {
        applySnapshot(self, initialState);
      },
      delay: (ms: number) =>
        new Promise((resolve) => {
          setTimeout(resolve, ms);
        }),
    };
  })
  .actions((self) => ({
    getPoliciesList: flow(function* () {
      log('getPoliciesList >>>>');
      try {
        self.flags.isFetchingPoliciesListData = true;
        const response = yield axios.get(endpoints.getPoliciesList);
        log('getPoliciesListResponse >>>>', response);
        if (response.data.success) {
          self.policiesList = cast(response.data.policiesList);
        }
      } catch (err) {
        errorLog('getPoliciesListError >>>>', err);
      } finally {
        self.flags.isFetchingPoliciesListData = false;
      }
    }),
  }));

export interface IPoliciesStore extends Instance<typeof PoliciesStore> {}

export default PoliciesStore;
