import { Instance, types } from 'mobx-state-tree';

const AccidentAdjustLoss = types.model({
  accidentAdjustExpensesId: types.maybeNull(types.string),
  accidentId: types.maybeNull(types.string),
  concept: types.maybeNull(types.string),
  amount: types.maybeNull(types.number),
  total: types.maybeNull(types.number),
  operator: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
});

export interface IAccidentAdjustLoss extends Instance<typeof AccidentAdjustLoss> {}
export default AccidentAdjustLoss;
