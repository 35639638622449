import { Instance, types } from 'mobx-state-tree';

const AccidentEvidenceImage = types.model({
  accidentEvidenceImageId: types.maybeNull(types.string),
  accidentId: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
});

export interface IAccidentEvidenceImage extends Instance<typeof AccidentEvidenceImage> {}
export default AccidentEvidenceImage;
