import { Instance, types } from 'mobx-state-tree';

const Accident = types.model({
  accidentId: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  insuranceRef: types.maybeNull(types.string),
  insuredRef: types.maybeNull(types.string),
  andorRef: types.maybeNull(types.string),
  visitStartDate: types.maybeNull(types.string),
  visitEndDate: types.maybeNull(types.string),
  contactName: types.maybeNull(types.string),
  contactAddress: types.maybeNull(types.string),
  insuranceAdjusterName: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  assignedUser: types.maybeNull(types.string),
  accidentType: types.maybeNull(types.string),
  businessName: types.maybeNull(types.string),
  policy: types.maybeNull(types.string),
  accidentTypeId: types.maybeNull(types.string),
  businessId: types.maybeNull(types.string),
  policyId: types.maybeNull(types.string),
  assignedUserId: types.maybeNull(types.string),
  insuranceId: types.maybeNull(types.string),
  insuranceName: types.maybeNull(types.string),
  visitObservations: types.maybeNull(types.string),
  accidentDescription: types.maybeNull(types.string),
  estimatedDamageAmount: types.maybeNull(types.number),
  observations: types.maybeNull(types.string),
  accidentDate: types.maybeNull(types.string),
  notProceedMsg: types.maybeNull(types.string),
  coverage: types.maybeNull(types.string),
  maximumLimitOfLiability: types.maybeNull(types.string),
  deductible: types.maybeNull(types.string),
  coinsurance: types.maybeNull(types.string),
  startValidityDate: types.maybeNull(types.string),
  endValidityDate: types.maybeNull(types.string),
  currency: types.maybeNull(types.string),
});

export interface IAccident extends Instance<typeof Accident> {}
export default Accident;
