import { ChangeEvent, FC, useState } from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';

import Input from '../Input';

import eye from '@/assets/images/eye.svg';
import eyeClose from '@/assets/images/eye-closed.svg';

import useStyles from './styles';

export interface PasswordInputProps {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  id?: string;
  name?: string;
  'data-testid'?: string;
  autoFocus?: boolean;
  fullWidth?: boolean;
  required?: boolean;
  error?: boolean;
  label?: string;
  inputProps?: Record<string, unknown>;
  inputRef?: any;
}

const PasswordInput: FC<PasswordInputProps> = ({
  onChange,
  value,
  id,
  inputProps,
  'data-testid': testId,
  autoFocus = false,
  fullWidth = true,
  ...props
}) => {
  const css = useStyles();
  const [passwordVisibility, togglePasswordVisibility] = useState(false);

  const toggleVisibility = () => togglePasswordVisibility(!passwordVisibility);
  const endImage = passwordVisibility ? eyeClose : eye;

  const InputProps = {
    endAdornment: (
      <InputAdornment position="end">
        <img
          className={css.endIcon}
          data-testid="togglePassVisibility"
          onClick={toggleVisibility}
          src={endImage}
          alt="toggle visibility"
        />
      </InputAdornment>
    ),
  };

  return (
    <Input
      id={id || 'passwordInput'}
      data-testid={testId || 'passwordInput'}
      autoFocus={autoFocus}
      value={value}
      inputProps={{
        ...inputProps,
        type: !passwordVisibility ? 'password' : 'text',
        autoComplete: 'off',
      }}
      InputProps={InputProps}
      onChange={onChange}
      fullWidth={fullWidth}
      {...props}
    />
  );
};

export default PasswordInput;
