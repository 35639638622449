import { types } from 'mobx-state-tree';

// STORES
import TestStore, { ITestStore } from './test';
import UiStore, { IUi } from './ui';
import LoginStore, { ILoginStore } from './login';
import UserStore, { IUserStore } from './user';
import RoleStore, { IRoleStore } from './role';
import AccidentsStore, { IAccidentsStore } from './accidents';
import BusinessStore, { IBusinessStore } from './business';
import PoliciesStore, { IPoliciesStore } from './policies';
import InsurersStore, { IInsurersStore } from './insurers';

export const GlobalStore = types.model({
  testStore: TestStore,
  uiStore: UiStore,
  loginStore: LoginStore,
  userStore: UserStore,
  roleStore: RoleStore,
  accidentsStore: AccidentsStore,
  businessStore: BusinessStore,
  policiesStore: PoliciesStore,
  insurersStore: InsurersStore,
});

export interface IGlobalStore {
  testStore: ITestStore;
  uiStore: IUi;
  loginStore: ILoginStore;
  userStore: IUserStore;
  roleStore: IRoleStore;
  accidentsStore: IAccidentsStore;
  businessStore: IBusinessStore;
  policiesStore: IPoliciesStore;
  insurersStore: IInsurersStore;
}

const store = GlobalStore.create({
  testStore: TestStore.create({}),
  uiStore: UiStore.create({}),
  loginStore: LoginStore.create({}),
  userStore: UserStore.create({}),
  roleStore: RoleStore.create({}),
  accidentsStore: AccidentsStore.create({}),
  businessStore: BusinessStore.create({}),
  policiesStore: PoliciesStore.create({}),
  insurersStore: InsurersStore.create({}),
});

export default store;
