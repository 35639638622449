import { FC } from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.andorTheme.andor,
      color: theme.andorTheme.cotton,
      borderRadius: 100,
      boxShadow: '0px 8px 24px rgba(1, 70, 81, 0.16)',
      fontWeight: 'bold',
      '&:hover': {
        color: theme.andorTheme.cotton,
        boxShadow: '0px 8px 24px rgba(1, 70, 81, 0.16)',
        cursor: 'pointer',
      },
    },
    containedSizeLarge: {
      fontSize: 16,
      lineHeight: '20px',
      padding: '14px 21px',
      minWidth: '266px',
      '@media (max-width: 320px)': {
        minWidth: '100%',
      },
    },
    disabled: {
      backgroundColor: `${theme.andorTheme.stone} !important`,
      color: `${theme.andorTheme.dolphin} !important`,
    },
    spinner: {
      marginRight: '0.5em',
      color: theme.andorTheme.dolphin,
    },
  }),
);

interface OysterButtonProps extends ButtonProps {
  submitting?: boolean;
}

const FormButton: FC<OysterButtonProps> = ({
  variant = 'contained',
  children,
  submitting,
  ...restProps
}) => {
  const classes = useStyles();

  return (
    <Button
      variant={variant}
      classes={{
        root: classes.root,
        containedSizeLarge: classes.containedSizeLarge,
        disabled: classes.disabled,
      }}
      {...restProps}
    >
      {submitting && <CircularProgress className={classes.spinner} size={15} thickness={5} />}
      {children}
    </Button>
  );
};

export default FormButton;
