import { types, applySnapshot, getSnapshot, Instance, flow, cast, getRoot } from 'mobx-state-tree';
import axios from 'axios';
import debug from 'debug';

import { IGlobalStore } from '@/store';
import endpoints from '@/config/endpoints';
import Flags from './models/Flags';
import Insurers from './models/Insurers';

const log = debug('store:insurers:log');
const errorLog = debug('store:insurers:error');

const InsurersStore = types
  .model({
    flags: types.optional(Flags, {}),
    insurersList: types.optional(types.array(Insurers), []),
  })
  .views((self) => ({
    get root(): IGlobalStore {
      return getRoot(self);
    },
  }))
  .actions((self) => ({
    updateField: (field: string, value: string | number | boolean | null) => {
      applySnapshot(self, { ...self, [field]: value });
    },
  }))
  .actions((self) => {
    let initialState = {};
    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      resetStore: () => {
        applySnapshot(self, initialState);
      },
      delay: (ms: number) =>
        new Promise((resolve) => {
          setTimeout(resolve, ms);
        }),
    };
  })
  .actions((self) => ({
    getInsurersList: flow(function* () {
      log('getPoliciesListList >>>>');
      try {
        self.flags.isFetchingInsurersListData = true;
        const response = yield axios.get(endpoints.getInsurersList);
        log('getPoliciesListListResponse >>>>', response);
        if (response.data.success) {
          self.insurersList = cast(response.data.insurersList);
        }
      } catch (err) {
        errorLog('getPoliciesListListError >>>>', err);
      } finally {
        self.flags.isFetchingInsurersListData = false;
      }
    }),
  }));

export interface IInsurersStore extends Instance<typeof InsurersStore> {}

export default InsurersStore;
