import { types, applySnapshot, getSnapshot, Instance, flow, getRoot, cast } from 'mobx-state-tree';
import axios from 'axios';
import debug from 'debug';

import { IGlobalStore } from '@/store';
import endpoints from '@/config/endpoints';
import Flags from './models/Flags';
import User from './models/User';

const log = debug('store:user:log');
const errorLog = debug('store:user:error');

const UserStore = types
  .model({
    userId: types.maybeNull(types.string),
    userData: types.optional(User, {}),
    userList: types.optional(types.array(User), []),
    flags: types.optional(Flags, {}),
  })
  .views((self) => ({
    get root(): IGlobalStore {
      return getRoot(self);
    },
  }))
  .actions((self) => ({
    updateField: (field: string, value: string | number | boolean | null) => {
      applySnapshot(self, { ...self, [field]: value });
    },
  }))
  .actions((self) => {
    let initialState = {};
    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      resetStore: () => {
        applySnapshot(self, initialState);
      },
      delay: (ms: number) =>
        new Promise((resolve) => {
          setTimeout(resolve, ms);
        }),
    };
  })
  .actions((self) => ({
    getUserData: flow(function* () {
      const { loginStore } = self.root;
      log('getUserData >>>>');
      try {
        self.flags.isFetchingUserData = true;
        const response = yield axios.get(endpoints.getUser);
        log('getUserDataResponse >>>>', response);
        if (response.data.success) {
          loginStore.flags.updateFlag('isAuthenticated', true);
          self.userData = cast(response.data.userData);
        }
      } catch (err) {
        errorLog('getUserDataError >>>>', err);
        loginStore.resetStore();
      }
    }),
    getUserList: flow(function* () {
      const { loginStore } = self.root;
      log('getUserList >>>>');
      try {
        self.flags.isFetchingUserListData = true;
        const response = yield axios.get(endpoints.getUserList);
        log('getUserListResponse >>>>', response);
        if (response.data.success) {
          self.userList = cast(response.data.userList);
        }
      } catch (err) {
        errorLog('getUserListError >>>>', err);
        loginStore.resetStore();
      } finally {
        self.flags.isFetchingUserListData = false;
      }
    }),
  }));

export interface IUserStore extends Instance<typeof UserStore> {}

export default UserStore;
