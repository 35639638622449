import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
  return createStyles({
    header: {
      paddingTop: 72,
    },
    headerReverse: {
      marginLeft: 'auto',
    },
    subtitleContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    subtitleContainerReverse: {
      flexDirection: 'row-reverse',
    },
    subtitle: {
      textTransform: 'uppercase',
      lineHeight: '1em',
      color: theme.andorTheme.shark,
      fontSize: 12,
    },
    title: {
      padding: '0 55px',
      margin: '5px 0 15px 0',
      fontSize: 24,
    },
    icon: {
      background: theme.andorTheme.bottle,
      width: 25,
      height: 25,
      color: theme.andorTheme.cotton,
      margin: '0 15px',
      '&:hover': {
        background: theme.andorTheme.brandBlue,
      },
      '& .MuiSvgIcon-root': {
        fontSize: '1.2rem',
      },
    },
  });
};

export default makeStyles(styles);
