import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      height: '100%',
      width: '100%',
      maxWidth: '50em',
      margin: 0,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    noAuthContainer: {
      alignSelf: 'center',
      maxWidth: '44em',
      paddingBottom: '3.75em',
    },
    formNoAuth: {
      maxWidth: '486px',
      margin: 0,
      '@media (max-width: 1100px)': {
        margin: '0 auto',
      },
      '@media (max-width: 700px)': {
        maxWidth: '100%',
      },
    },
    title: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '1.5em',
      lineHeight: '1.4em',
      margin: 0,
      padding: '1rem 0 1.2rem',
      color: theme.andorTheme.chocolate,
      '&.textCenter': {
        textAlign: 'center',
      },
      '@media (max-width:640px)': {
        textAlign: 'center',
      },
      '&.textLeft': {
        '@media (max-width:640px)': {
          textAlign: 'left',
        },
      },
    },
    text: {
      fontFamily: 'Montserrat',
      color: theme.andorTheme.charcoal,
      fontSize: 14,
      lineHeight: '22px',
      margin: 0,
      padding: '0.5rem 0 1rem',
      '&.textCenter': {
        textAlign: 'center',
      },
      '@media (max-width:640px)': {
        textAlign: 'center',
      },
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      paddingTop: theme.spacing(1),
    },
  }),
);

export default styles;
