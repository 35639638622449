import { types, applySnapshot, getSnapshot, Instance, flow } from 'mobx-state-tree';
import axios from 'axios';

import endpoints from '@/config/endpoints';
import Flags from './models/Flags';

const TestStore = types
  .model({
    flags: types.optional(Flags, {}),
  })
  .actions((self) => ({
    updateField: (field: string, value: string | number | boolean | null) => {
      applySnapshot(self, { ...self, [field]: value });
    },
  }))
  .actions((self) => {
    let initialState = {};
    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      resetStore: () => {
        applySnapshot(self, initialState);
      },
    };
  })
  .actions(() => ({
    test: flow(function* () {
      const response = yield axios.post(endpoints.test, { test: 'Hola mundo' });
      console.log(response);
    }),
  }));

export interface ITestStore extends Instance<typeof TestStore> {}

export default TestStore;
