import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    backdrop: {
      zIndex: 200,
      background: theme.andorTheme.cotton,
    },
  }),
);

export default useStyles;
