import { FC } from 'react';
import clsx from 'clsx';

import InputAdornment from '@material-ui/core/InputAdornment';
import { InputClassKey } from '@material-ui/core/Input';
import { InputLabelClassKey } from '@material-ui/core/InputLabel';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';

import errorFilled from '@/assets/images/error-filled.svg';
import useStyles from './styles';

const Input: FC<TextFieldProps> = ({
  variant = 'outlined',
  size = 'small',
  children,
  ...restProps
}) => {
  const css = useStyles();

  const inputLabelClasses: Partial<ClassNameMap<InputLabelClassKey>> = {
    ...(restProps?.InputLabelProps?.classes ?? {}),
    root: clsx([css.label, restProps?.InputLabelProps?.classes?.root]),
    shrink: clsx([css.shrink, restProps?.InputLabelProps?.classes?.shrink]),
    focused: clsx([css.labelFocused, restProps?.InputLabelProps?.classes?.focused]),
    marginDense: clsx([css.marginDenseLabel, restProps?.InputLabelProps?.classes?.marginDense]),
    asterisk: clsx([css.labelAsterisk, restProps?.InputLabelProps?.classes?.asterisk]),
  };

  let endAdornment = restProps?.InputProps?.endAdornment;

  if (restProps?.error && !endAdornment) {
    endAdornment = (
      <InputAdornment position="end">
        <img
          src={errorFilled}
          alt="error icon"
          data-testid="inputAdornmentError"
          width={20}
          height={20}
        />
      </InputAdornment>
    );
  }

  const inputClasses: Partial<ClassNameMap<InputClassKey>> = {
    ...(restProps?.InputProps?.classes ?? {}),
    root: clsx([css.input, restProps?.InputProps?.classes?.root]),
    error: clsx([css.error, restProps?.InputProps?.classes?.error]),
    input: clsx([css.baseInput, restProps?.InputProps?.classes?.input]),
  };

  return (
    <TextField
      {...restProps}
      variant={variant}
      size={size}
      InputLabelProps={{
        ...restProps.InputLabelProps,
        classes: inputLabelClasses,
      }}
      InputProps={{
        ...restProps.InputProps,
        classes: inputClasses,
        endAdornment,
      }}
    >
      {children}
    </TextField>
  );
};

export default Input;
