import { FC, ReactElement } from 'react';
import Grid from '@material-ui/core/Grid';

export interface FormTitleProps {
  title: string;
  className: string;
  titleCss: string;
  infoCss?: string;
  info?: string;
  infoComponent?: ReactElement;
}

const FormTitle: FC<FormTitleProps> = ({
  className,
  titleCss,
  infoCss,
  title,
  info,
  infoComponent,
}) => {
  return (
    <Grid item xs={12} className={className}>
      <h3 className={titleCss}>{title}</h3>
      {info && <p className={infoCss}>{info}</p>}
      {infoComponent && infoComponent}
    </Grid>
  );
};

export default FormTitle;
