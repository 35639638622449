import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    endIcon: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }),
);

export default useStyles;
