import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = createStyles({
  ToastContainer: () => ({
    position: 'fixed',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    top: 10,
    bottom: 0,
    left: 0,
    right: 0,
    pointerEvents: 'none',
  }),
});

export default makeStyles(useStyles);
