import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AuthRoute from '@/components/AuthRoute';
import UnAuthRoute from '@/components/UnAuthRoute';
import AccessRoute from '@/components/AccessRoute';
import { allowedActions } from '@/libraries/accessManager/constants/allowedActions';
import { ROUTES } from '@/constants/routes';

import Login from '@/pages/Login/login';
import Home from '@/pages/Home';

const Router: FC = () => {
  return (
    <Switch>
      <Route path={ROUTES.LOGIN}>
        <UnAuthRoute>
          <Login />
        </UnAuthRoute>
      </Route>
      <Route path={ROUTES.HOME}>
        <AuthRoute>
          <AccessRoute allowedAction={allowedActions.HOME_SCREEN}>
            <Home />
          </AccessRoute>
        </AuthRoute>
      </Route>
      <Redirect from="*" to={ROUTES.LOGIN} />
    </Switch>
  );
};

export default observer(Router);
