import React, { FC, useState, useEffect, SVGProps, ReactNode } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { IoLogOut, IoHome } from 'react-icons/io5';

import useStore from '@/hooks/useStore';
import useLogout from '@/hooks/useLogout';
import isURL from '@/utils/isURL';

import useHasActionAccess from '@/libraries/accessManager/access/useHasActionAccess';
import { allowedRolesDisplayEs } from '@/libraries/accessManager/constants/allowedRoles';
import { allowedActions } from '@/libraries/accessManager/constants/allowedActions';
import { ROUTES } from '@/constants/routes';

import SidebarNavigation from '../../SidebarNavigation';
import SidebarUserFooter from '../../SidebarUserFooter';
import WebDashboardLayout from './webDashboardLayout';

const URL_FIRST_PART = /^\/[\w-]*/;

interface IconProps extends SVGProps<SVGSVGElement> {
  defaultColor?: boolean;
}

interface ISidebarItem {
  title: string;
  disabled: boolean;
  icon: FC<IconProps>;
  link: string;
  tooltip?: string;
  itemId?: string;
}

interface MainLayoutProps {
  children: ReactNode;
}

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const { userStore, roleStore } = useStore();
  const { pathname } = useLocation();
  const { logoutUser } = useLogout();
  const history = useHistory();

  const [sidebarItems, setSidebarItems] = useState<ISidebarItem[]>([]);
  const [currentRoute] = pathname?.match(URL_FIRST_PART) || ['/'];

  const hasHomeAccess = useHasActionAccess({ action: allowedActions.HOME_SCREEN });

  const onSidebarClick = (link: string) => {
    if (!isURL(link)) {
      history.push(link);
    } else {
      window.location.href = link;
    }
  };

  const role = roleStore.role as keyof typeof allowedRolesDisplayEs;

  useEffect(() => {
    const items = [] as ISidebarItem[];

    if (hasHomeAccess) {
      items.push({
        title: 'Inicio',
        disabled: false,
        icon: IoHome,
        link: ROUTES.HOME,
      });
    }

    setSidebarItems(items);
  }, [pathname, hasHomeAccess]);

  return (
    <WebDashboardLayout
      sidebarMenu={
        <SidebarNavigation items={sidebarItems} selected={currentRoute} onChange={onSidebarClick} />
      }
      leftHeaderComp={
        <IoLogOut style={{ color: '#FFF', cursor: 'pointer' }} onClick={logoutUser} />
      }
      footerComp={
        <SidebarUserFooter
          disableGoToProfile
          logout={logoutUser}
          fullName={userStore.userData?.userFirstName}
          accountType={allowedRolesDisplayEs[role]}
          logoutStr="Cerrar Sesión"
        />
      }
    >
      {children}
    </WebDashboardLayout>
  );
};

export default observer(MainLayout);
